var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"odd"},[_c('div',{class:['btn odd-btn', _vm.statusClass, _vm.picked],attrs:{"disabled":_vm.locked,"id":_vm.getOddID(
        _vm.match_id,
        _vm.market_id,
        _vm.outcome_id,
        _vm.specifier,
        _vm.boosted ? 'boosted' : 'odd'
      ),"oddStatus":_vm.oddStatus,"oddValue":_vm.odd,"oddActive":_vm.oddActive,"producerId":_vm.producer_id,"producerStatus":_vm.producerStatus},on:{"click":function($event){return _vm.addPick(
        _vm.sport_id,
        _vm.match_id,
        _vm.market_name,
        _vm.market_id,
        _vm.home_team,
        _vm.away_team,
        _vm.producer_id,
        _vm.specifier,
        _vm.pick,
        _vm.live,
      )}}},[_c('div',{staticClass:"grey-text text-center odd-indicator"},[_vm._v(_vm._s(_vm.aliasName))]),_c('button',[(!_vm.locked)?_c('span',{staticClass:"odd-direction",class:_vm.directionClass}):_vm._e(),(_vm.locked)?_c('span'):_c('span',{},[_c('span',{staticClass:"odd-number"},[_vm._v(_vm._s(_vm.odd))])]),(_vm.locked)?_c('span',{staticStyle:{"height":"1.5em"}},[_c('LockIcon',{attrs:{"size":_vm.iconSize,"fill-color":"black"}})],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }